<template>
  <div class="shop"
    style="font-size: 18px; padding-bottom: 35px">
    <div class="information"
      v-if="spShow">
      <van-sticky :offset-top="0">
        <div>
          <van-search v-model="listfrom.houseHolder"
            placeholder="请输入户主姓名"
            show-action
            @search="onSearch">
            <template #action>
              <div @click="onSearch">搜索</div>
            </template>
          </van-search>
        </div>
      </van-sticky>
      <div class="center"
        v-for="item in hzlist"
        :key="item.index"
        style="border: 1px solid rgb(222 206 206)">
        <div class="shopTitle van-ellipsis"
          style="padding-top: 0; padding-bottom: 0"
          @click="zhList(item.BFaId)">
          {{ item.Title }}
        </div>
        <div class="Content"
          style="padding: 8px 10px">
          <div @click="zhList(item.BFaId)">
            <div style="
                font-size: 16px;
                color: #333;
                margin: 5px 0;
                border-bottom: 1px solid rgb(233 224 224);
              ">
              <span style="line-height: 30px;font-">房屋信息</span>
            </div>
            <div style="font-size: 16px; color: #333; margin: 5px 0">
              <span style="margin-right: 20px">户主：{{ item.Householder }}
                <i class="iconfont icon-lianzufang"
                  style="color: red"
                  v-if="item.FamilyKind == 6"></i>
              </span>
            </div>
            <div style="font-size: 16px; color: #333; margin: 5px 0">
              <span>房间号：{{ item.BRNO }}</span>
            </div>
            <span class="centertime"
              style="color: #333; font-size: 16px">
              <span>家庭人数：{{ item.FamilySize }}</span>
            </span>
          </div>
          <div class="hidAddOn">
            <van-button round
              type="primary"
              size="mini"
              style="text-center: right; font-size: 14px"
              @click="add(item)">新增家庭成员</van-button>
            <van-button round
              type="info"
              size="mini"
              color="#617bfa"
              style="text-center: right; font-size: 14px"
              @click="updataXq(item)">编辑</van-button>
          </div>
        </div>
      </div>
      <div class="center"
        v-show="!jiatingList"
        v-for="item in list"
        :key="item.index"
        style="border: 1px solid rgb(222 206 206)">
        <div class="shopTitle van-ellipsis"
          style="padding-top: 0; padding-bottom: 0">
          {{ item.Title }}
        </div>
        <div class="Content"
          style="padding: 8px 10px">
          <div style="display: inline-block"
            @click="jtXq(item.HId)">
            <div style="font-size: 16px; color: #333; margin: 5px 0">
              <span style="margin-right: 20px">姓名：{{ item.Name }}
                <i class="iconfont icon-junren"
                  style="color: red"
                  v-if="item.Veterans == '是'"></i>
                <i class="iconfont icon-dangyuan"
                  style="color: red"
                  v-if="item.PartyMember == '是'"></i>
                <i class="iconfont icon-canjirenzheng"
                  style="color: red"
                  v-if="item.Disabled == '是'"></i>
              </span>
            </div>
            <div class="centertime"
              style="color: #333; font-size: 16px; margin: 5px 0">
              <span>与户主关系：{{ item.RelationHouserName }}</span>
            </div>
            <span class="centertime"
              style="color: #333; font-size: 16px; margin: 5px 0">
              <span>身份证号：{{ item.IdNumber }}</span>
            </span>
            <div class="centertime"
              style="color: #333; font-size: 16px; margin: 5px 0">
              <span>地址：{{item.RegisterAddr}}</span>
            </div>
          </div>
          <div class="hidAddOn">
            <van-button round
              type="info"
              size="mini"
              style="text-center: right; font-size: 14px"
              color="#617bfa"
              @click="jtbianji(item.HId)">编辑</van-button>
            <van-button round
              type="danger"
              size="mini"
              style="text-center: right; font-size: 14px"
              @click="delet(item.HId)">删除</van-button>
          </div>
        </div>
        <!-- <div style="text-align:right;margin-right:10px;"><van-button round type="info" size="mini" style="text-center:right;margin-right:10px;margin-bottom:10px;">编辑</van-button></div> -->
      </div>
      <div v-if="spJtShow">
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 45%"
            @click="addXq()"
            color="#617bfa">新增家庭</van-button>
          <van-button round
            type="primary"
            size="normal"
            style="
              text-center: right;
              font-size: 16px;
              width: 45%;
              float: right;
            "
            @click="showPopup()">绑定商铺</van-button>
        </div>
      </div>
      <div v-if="!spJtShow">
        <div style="width: 95%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            type="info"
            size="normal"
            style="text-center: right; font-size: 16px; width: 100%"
            @click="addXq()"
            color="#617bfa">新增家庭</van-button>
        </div>
      </div>
      <!-- <van-popup v-model:show="show" position="top" :style="{ height: '30%' }" /> -->
      <!-- 没有更多数据了 -->
      <div v-if="hidden"
        class="hidden">───── 没有更多数据了 ─────</div>
    </div>
    <div v-if="!spShow">
      <div class="center"
        v-for="item in bdlist"
        :key="item.index"
        style="
          margin: 3% auto;
          width: 90%;
          border: 1px solid rgb(222 206 206);
          border-radius: 10px;
        ">
        <div class="shopTitle van-ellipsis"
          style="padding-top: 10px; padding-bottom: 0">
          名称：{{ item.Title }}
        </div>
        <div class="Content"
          style="padding: 8px 10px">
          <div style="display: inline-block">
            <div class="centertime"
              style="color: #333; font-size: 16px; margin: 5px 0">
              <span>所属行业：{{ item.ShopTypeDesc }}</span>
            </div>
            <div class="centertime"
              style="color: #333; font-size: 16px; margin: 5px 0">
              <span>负责人：{{ item.Charger }}</span>
            </div>
            <span class="centertime"
              style="color: #333; font-size: 16px; margin: 5px 0">
              <span>联系电话：{{ item.Mobile }}</span>
            </span>
            <div class="centertime"
              style="color: #333; font-size: 16px; margin: 5px 0">
              <span>地址：{{ item.Address }}</span>
            </div>
          </div>
        </div>
      </div>
      <div style="">
        <div style="width: 100%; bottom: 0; position: fixed; padding: 10px">
          <van-button round
            type="primary"
            size="normal"
            style="font-size: 16px; width: 95%"
            @click="showPopup()">绑定商铺</van-button>
        </div>
      </div>
    </div>
    <van-popup v-model="show"
      closeable
      round
      position="bottom"
      :style="{ height: '70%' }">
      <div style="margin: 40px 0px; float: left; text-align: center; width: 100%">
        <div style="font-size: 18px">商铺列表</div>
        <van-cell-group v-for="item in splist"
          :key="item.index"
          style="text-align: left; border-bottom: 1px solid #ccd3db">
          <van-cell @click="bangding(item.ShopId)"
            :title="'名称：' + item.Title"
            :label="'地点：' + item.Address"
            style="font-size: 16px"
            label-class="label"
            title-style="width:60%;" />
        </van-cell-group>
        <van-empty v-if="wushuju"
          description="暂无数据" />
      </div>
    </van-popup>

    <!-- 家庭成员 -->
    <div v-if="jiatingList">
      <van-popup v-model="showJt"
        closeable
        round
        position="bottom"
        :style="{ height: '70%' }">
        <div style="margin: 40px 0px; float: left; text-align: center; width: 100%">
          <div style="font-size: 15px">家庭成员</div>
          <div class="center"
            v-for="item in list"
            :key="item.index"
            style="border: 1px solid rgb(222 206 206);border-radius:5px;text-align: left;">
            <div class="shopTitle van-ellipsis"
              style="padding-top: 0; padding-bottom: 0">
              {{ item.Title }}
            </div>
            <div class="Content"
              style="padding: 8px 10px">
              <div style="display: inline-block"
                @click="jtXq(item.HId)">
                <div style="font-size: 16px; color: #333; margin: 5px 0">
                  <span style="margin-right: 20px">姓名：{{ item.Name }}
                    <i class="iconfont icon-junren"
                      style="color: red"
                      v-if="item.Veterans == '是'"></i>
                    <i class="iconfont icon-dangyuan"
                      style="color: red"
                      v-if="item.PartyMember == '是'"></i>
                    <i class="iconfont icon-canjirenzheng"
                      style="color: red"
                      v-if="item.Disabled == '是'"></i>
                  </span>
                </div>
                <div class="centertime"
                  style="color: #333; font-size: 16px; margin: 5px 0">
                  <span>与户主关系：{{ item.RelationHouserName }}</span>
                </div>
                <span class="centertime"
                  style="color: #333; font-size: 16px; margin: 5px 0">
                  <span>身份证号：{{ item.IdNumber }}</span>
                </span>
                <div class="centertime"
                  style="color: #333; font-size: 16px; margin: 5px 0">
                  <span>地址：{{item.RegisterAddr}}</span>
                </div>
              </div>
              <div class="hidAddOn">
                <van-button round
                  type="info"
                  size="mini"
                  style="text-center: right; font-size: 14px"
                  color="#617bfa"
                  @click="jtbianji(item.HId)">编辑</van-button>
                <van-button round
                  type="danger"
                  size="mini"
                  style="text-center: right; font-size: 14px"
                  @click="delet(item.HId)">删除</van-button>
              </div>
            </div>
          </div>
        </div>
      </van-popup>
    </div>

  </div>
</template>
<script>
import {
  GetBuildFamilyPage,
  GetResidentListByFamily,
  DeleteResident,
  GetShopPage,
  RoomBindShop,
  GetShopByRridList,
} from "@/api/wangge";
import { getwgToken, setwgToken } from "@/utils/auth";
import { Col } from "vant";
import { Dialog } from "vant";
import Vue from "vue";
import { Toast } from "vant";
Vue.use(Toast);
export default {
  data () {
    return {
      riKind: 0, //tab栏
      hzlist: {}, //户主
      list: {},
      listfrom: {
        page: 1,
        status: 1,
        limit: 99,
        brId: "",
      }, //查询传参
      spfrom: {
        oCode: "",
        gId: "",
      },
      search: "", //搜索内容
      hidden: true, //没有更多数据了
      loading: false, //加载
      finished: false, //数据完全加载结束为 true
      total: undefined, //数据条数
      show: false,
      wushuju: false,
      zhfrom: {
        page: 1,
        limit: 10,
        bFaId: "",
        status: 1,
      },
      splist: {},
      bdlist: {},
      bdfrom: {},
      ydfrom: {},
      spShow: false,
      spJtShow: false,
      jiatingList: false,
      showJt: false,
    };
  },
  created () {
    this.getList();
    this.canshu();
  },
  methods: {
    canshu () {
      console.log(this.$route);
      if (this.$route.params.ShopShow == 0) {
        this.spShow = true;
      } else if (this.$route.params.ShopShow == 1) {
        this.spShow = false;
        this.shangpuYbd();
      } else {
        this.spShow = true;
      }
    },

    showPopup () {
      console.log();
      this.show = true;
      this.shangpu();
    },
    //已绑定商铺
    shangpuYbd () {
      this.ydfrom.brid = this.$route.params.BRId;
      this.ydfrom.accToken = getwgToken();
      GetShopByRridList(this.ydfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.bdlist = {};
              this.wushuju = true;
            } else {
              this.wushuju = false;
              this.bdlist = res.data.data;
            }
          }
        })
        .catch(() => { });
    },
    // 获取商铺列表
    shangpu () {
      // console.log(this.$route.query.BRId);
      console.log(this.$route.params);
      this.spfrom.oCode = this.$route.params.Code;
      this.spfrom.gId = this.$route.params.GId;
      this.spfrom.accToken = getwgToken();
      GetShopPage(this.spfrom)
        .then((res) => {
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.splist = {};
              this.wushuju = true;
            } else {
              this.wushuju = false;
              this.splist = res.data.data;
            }
          }
        })
        .catch(() => { });
    },
    //绑定商铺
    bangding (ShopId) {
      this.bdfrom.BRId = parseInt(this.$route.params.BRId);
      this.bdfrom.ShopId = ShopId;
      this.bdfrom.accToken = getwgToken();
      RoomBindShop(this.bdfrom)
        .then((res) => {
          console.log(res.data.code);
          if (res.data.code == 0) {
            Toast.success("绑定成功");
            this.show = false;
            this.shangpuYbd();
          } else {
            Toast.loading({
              message: res.data.msg,
              forbidClick: true,
            });
          }
        })
        .catch(() => { });
    },
    // 搜索
    onSearch () {
      this.listfrom.page = 1;
      // this.listfrom.houseHolder = this.listfrom.houseHolder
      this.getList();
    },
    // 获取住户列表
    getList () {
      this.listfrom.brId = this.$route.params.BRId;
      this.listfrom.accToken = getwgToken();
      GetBuildFamilyPage(this.listfrom)
        .then((res) => {
          this.list = {}
          if (res.data.code == 0) {
            if (res.data.count == 0) {
              this.hzlist = {};
              this.anniu("wu");
            } else if (res.data.count < 1) {
              this.spJtShow = false;
              this.jiatingList = false;
              this.hzlist = res.data.data;
              this.zhList(this.hzlist[0].BFaId);
              this.zhid = this.hzlist[0].BFaId;
              this.anniu("you");
            } else {
              this.spJtShow = false;
              this.jiatingList = true;
              this.anniu("you");
              this.hzlist = res.data.data;
            }
          }
        })
        .catch(() => { });
    },
    anniu (xyCan) {
      if (xyCan == "wu") {
        if (this.$route.params.GId != "undefined") {
          this.spJtShow = true;
        } else {
          this.spJtShow = false;
        }
      } else if (xyCan == "you") {
        console.log(this.$route.params.GId);
        if (this.$route.params.GId == "undefined") {
          this.spJtShow = false;
        } else {
          this.spJtShow = true;
        }
      }
    },
    // 获取家庭成员列表
    zhList (bFaId) {
      console.log(bFaId);
      this.showJt = true;
      //   this.listfrom.riKind = this.riKind;
      this.zhfrom.bFaId = bFaId;
      this.zhfrom.accToken = getwgToken();
      GetResidentListByFamily(this.zhfrom)
        .then((res) => {
          if (res.data.code == 0) {
            console.log(res.data.data.length);
            if (res.data.data.length == 0) {
              // this.hidden = true;
              this.finished = true;
              this.list = {};
            } else {
              this.list = res.data.data;
              this.total = res.data.count;
              this.finished = true;
              // this.hidden = false;
              for (var i = 0; i < this.list.length; i++) {
                var item = this.list[i].IssTime;
                item = this.dataFormat(item);
                this.list[i].IssTime = item;
              }
            }
          }
        })
        .catch(() => { });
    },

    add: function (row) {
      console.log(row);
      this.itemmark = JSON.stringify(row.BFaId);
      console.log(this.itemmark);
      this.$router.push({
        name: "jtbianji",
        query: { BFaId: this.itemmark, add: 1, BRId: row.BRId },
      });
    },
    jtbianji: function (HId) {
      console.log(HId);
      this.itemmark = JSON.stringify(HId);
      this.$router.push({
        name: "jtbianji",
        query: { HId: this.itemmark, add: 2 },
      });
    },
    delet: function (row) {
      console.log(row);
      Dialog.confirm({
        title: "提示",
        message: "确定要删除该条住户信息",
      })
        .then(() => {
          // on confirm
          console.log(312);
          DeleteResident({ accToken: getwgToken(), HId: row })
            .then((res) => {
              console.log(res.data.code);
              if (res.data.code == 0) {
                Toast.success("删除成功");
                this.getList();
              } else {
                Toast.fail(res.data.msg);
              }
            })
            .catch(() => { });
        })
        //
        .catch(() => {
          // on cancel
          console.log(123);
        });
    },
    jtXq: function (HId) {
      console.log(HId);
      this.itemmark = JSON.stringify(HId);
      this.$router.push({
        name: "chengyuanXq",
        query: { HId: this.itemmark },
      });
    },
    addXq: function () {
      this.itemmark = this.$route.params;
      this.$router.push({
        name: "roomAdd",
        query: { bfaId: this.itemmark, add: 1 },
      });
    },
    updataXq: function (item) {
      console.log(item.BFaId);
      this.itemmark = JSON.stringify(item.BFaId);
      this.$router.push({
        name: "roomAdd",
        query: { bfaId: this.itemmark, add: 2 },
      });
    },
    onClose () {
      this.setData({ show: false });
    },
    // tan点击切换事件
    onClick (e) {
      console.log(123123);
      console.log(e);
      this.fwList(e);
    },
    // 时间格式化
    dataFormat (value) {
      var year = value.substr(0, 4);
      var month = value.substr(5, 2);
      var day = value.substr(8, 2);
      var hour = value.substr(11, 2);
      var min = value.substr(14, 2);
      var second = value.substr(17, 2);
      return (
        year + "-" + month + "-" + day + " " + hour + ":" + min + ":" + second
      );
    },
  },
};
</script>
<style scoped>
.jiating {
  width: 45%;
}
.shopTitle {
  color: #333;
  padding: 10px;
}
.shop .information .center {
  position: relative;
  border-bottom: none;
  padding: 0px;
  border-radius: 5px;
}
.shop .center {
  width: 92%;
  margin: 10px auto;
  background: white;
}
.hidden {
  text-align: center;
  font-size: 12px;
  color: #ccc;
  margin: 10px 0px 25px 0px;
}
.hidAddOn {
  position: relative;
  text-align: right;
  margin-right: 10px;
}
.hidAddOn button {
  display: inline-block;
  padding: 12px;
}
.label {
  font-size: 16px;
}
</style>